<template>
  <div class="row main-content--finish-checkout xs-fill-white pt-15 lg:px-60" style="margin: auto;">
    <div class="selection-time text-center pb20 display-flex " style="position: relative;overflow: hidden;">
      <transition name="msg" mode="out-in">
        <div key="00-start" class="flex-1 slideInRight animated tracker--current-step" style="width: 100%;">
            <!--<div v-if="show ===0" class="text-red t16 bold pt10 title-order ">{{$t('payment_error')}}</div>-->
            <div v-if="show ===2" class="text-red t16 bold pt10 title-order ">{{$t('payment_error')}}</div>
          <div v-if="show ===1" class="text-green t16 bold pt10 title-order ">{{$t('payment_success')}}</div>
        </div>
      </transition>
    </div>

    <div>
      <div class="border-t text-center">
        <div class="py-10 px-30 display-flex justify-between flex-wrap justify-around ">
          <router-link class="no-underline px-10" to="/" >{{$t('home')}}</router-link>
          <router-link class="no-underline px-10" v-bind:to="'/order/'+$const.MENU_DEFAULT" >{{$t('all_deal')}}</router-link>
          <router-link class="no-underline px-10" to="/contact" >{{$t('contact_us')}}</router-link>
          <router-link class="no-underline px-10" to="/terms" >{{$t('terms')}}</router-link>
          <router-link class="no-underline px-10" to="/privacy" >{{$t('footer.cft_sec')}}</router-link>
        </div>
        <p class="text-grey text-center py-10">
          <span>{{$const.COPY_RIGHT}}</span>
          <span role="img" aria-label="Copyright" class="pr-5">©</span>
          <span>{{$const.COPY_RIGHT_MK}}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
let vm = null
export default {
  beforeMount: function () {

  },
  data () {
    return {
      show: 0,
      transaction: ''
    }
  },
  destroyed () {
    clearInterval(this.itervalObj)
    return false
  },
  created () {
    vm = this
    let transaction = this.$route.params.transaction ? this.$route.params.transaction : ''
    if (transaction === '') {
      let fullPath = this.$route.fullPath
      let urlArr = fullPath.split('?')
      let hashParam = urlArr[urlArr.length - 1]
      transaction = hashParam.replace('transaction=', '')
    }

    vm.transaction = transaction
  },
  mounted () {
    vm = this
    this.axios.apiPayment.getPaymentStatus(vm.transaction,
      function (response) {
        
        if(response.data.data.item.status !== 1) {
          vm.show = 2
        } else {
          vm.show = 1
        }

        if (response.data.data.item.status === 1) {
          vm.itervalObj = setInterval(function () {
            vm.$session.remove('cart')
            vm.$session.remove('disposition')
            clearInterval(vm.itervalObj)
            vm.$router.push({ 'name': 'tracker-order', 'params': { 'uuid': response.data.data.item.order } })
          }, 10000)
        }
      }
    )
  }
}

</script>

<style>
  .msg-enter-active,
  .msg-leave-active {
    transition: all 0.5s;
  }

  .msg-enter,
  .msg-leave-to {
    opacity: 0;
  }

  .msg-enter {
    transform: translateX(31px);
  }

  .msg-leave-active {
    transform: translateX(-31px);
  }
</style>
